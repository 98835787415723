import React, { memo, useState, useEffect } from "react";
import Card from "../../components/Card/Card.jsx";
import CardBody from "../../components/Card/CardBody.jsx";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, InputAdornment, Typography } from "@mui/material";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import FormikTextField from "../../components/FormikValidatedComponents/TextField";
import axios from "axios";
import { Formik, Form, Field } from "formik";
import { string, object } from "yup";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { withRouter } from "../../ContextAndHOC/WithRouter";
import { API_MOUNT_PATH } from "../../constants";
import { withMediaQuery } from "../../components/CustomInput/CustomMaterialUIWithWidth";
import Box from "@mui/material/Box";
import { CommonStyleObject } from "../../components/CSS/CommonCss";
import ResetPasswordStyleObject from "../../components/CSS/ResetPassWordStyle";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

const ForgetPassword = memo(function ForgetPassword(props) {
  const { theme, t } = props;
  const classes = {
    ...CommonStyleObject(theme),
    ...ResetPasswordStyleObject(theme),
  };
  const yupSchema = object().shape({
    email: string()
      .required(t("common.COMMON__FIELD_REQUIRED_MESSAGE"))
      .matches(
        /([a-z0-9][-a-z0-9_\+\.]*[a-z0-9])@([a-z0-9][-a-z0-9\.]*[a-z0-9]\.(arpa|root|aero|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|ac|ad|ae|af|ag|ai|al|am|an|ao|aq|ar|as|at|au|aw|ax|az|ba|bb|bd|be|bf|bg|bh|bi|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|cr|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|ee|eg|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gg|gh|gi|gl|gm|gn|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|im|in|io|iq|ir|is|it|je|jm|jo|jp|ke|kg|kh|ki|km|kn|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|mv|mw|mx|my|mz|na|nc|ne|nf|ng|ni|nl|no|np|nr|nu|nz|om|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tl|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|([0-9]{1,3}\.{3}[0-9]{1,3}))/,
        t("login.L__LOGIN__LOGIN_EMAIL_ERROR")
      ),
  });
  const [stateForget, setStateForget] = useState({
    resetpasswordmsg: false,
    popUpMsg: "",
    invalidEmail: false,
  });
  useEffect(() => {
    if (props.location.state === "error") {
      setStateForget({
        ...stateForget,
        invalidEmail: true,
        popUpMsg: t("common.COMMON__MISSING_RESET_TOKEN"),
      });
    }
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    axios
      .post(API_MOUNT_PATH + "/providerauthservice/forgetPassword", {
        emailid: values.email,
      })
      .then((result) => {
        if (result.data.status === 1) {
          setStateForget({
            ...stateForget,
            resetpasswordmsg: true,
            invalidEmail: false,
            popUpMsg: props.t(
              "forgotPassword.F__FORGOT__WE_HAVE_SENT_A_RESET_LINK"
            ),
          });
          setSubmitting(false);
        } else if (result.data.status === 0) {
          setStateForget({
            ...stateForget,
            invalidEmail: true,
            popUpMsg: result.data.statusDescription,
          });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        if (Object.assign({}, err.response).status === 422) {
          setStateForget({
            ...stateForget,
            invalidEmail: true,
            popUpMsg: props.t("login.L__EMAIL_NOT_REGISTERED"),
          });
        }
      });
  };
  let HeaderText = stateForget.popUpMsg;
  return (
    <>
      <Helmet>
        <title>{t("login.L__LOGIN_FORGET_TITLE_TEXT")}</title>
      </Helmet>
      <Formik
        initialValues={{ email: "" }}
        onSubmit={handleSubmit}
        validationSchema={yupSchema}
      >
        {(props) => (
          <Form>
            <Box sx={classes.outSideLayout}>
              <Typography
                variant="h5"
                sx={[classes.setPasswordTitle, classes.forgetTitle]}
              >
                {t("forgotPassword.F__FORGOT__FOR_GOT_YOUR_PASSWORD")}
              </Typography>
              <Typography sx={[classes.setPasswordTitle, classes.dis]}>
                {t("forgotPassword.F__FORGOT__FOR_GOT_PASSWORD_MESSAGE")}
              </Typography>
              <Card sx={classes.cardShadow}>
                <CardBody sx={{ p: "24px" }}>
                  {stateForget.invalidEmail && (
                    <Alert severity="error" sx={classes.invalidtoken}>
                      {HeaderText}
                    </Alert>
                  )}
                  <GridContainer
                    sx={stateForget.invalidEmail && { pt: "24px" }}
                  >
                    {!stateForget.resetpasswordmsg && (
                      <>
                        <GridItem sx={{ p: "0px" }}>
                          <Field
                            name="email"
                            fullWidth
                            label={t(
                              "forgotPassword.F__FORGOT__FOR_GOT_EMAIL_ADDRESS"
                            )}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <FontAwesomeIcon
                                    icon={faUser}
                                    style={{
                                      color:
                                        theme.palette.text
                                          .textSecondaryColorNew,
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            component={FormikTextField}
                          />
                        </GridItem>
                        <GridItem sx={{ p: "0px" }}>
                          <Button
                            type="submit"
                            sx={[classes.primaryBtn, classes.btn]}
                            size="small"
                          >
                            {t(
                              "forgotPassword.F__FORGOT__FOR_GOT_CONFIRM_BUTTON"
                            )}
                          </Button>
                        </GridItem>
                      </>
                    )}
                    {stateForget.resetpasswordmsg && (
                      <Alert severity="success" sx={classes.success}>
                        {HeaderText}
                      </Alert>
                    )}
                    <GridItem sx={{ p: "0px" }}>
                      <Box
                        component={Link}
                        sx={classes.linkBtn}
                        to={"/guest/Login"}
                      >
                        {t("forgotPassword.F__FORGOT__FOR_GOT_BACK")}
                      </Box>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
});

export default withTranslation()(withMediaQuery()(withRouter(ForgetPassword)));
